import React from 'react';

import styled from 'styled-components';
const Wrapper = styled.section`
  background-color: #eaeaeafc;
  color: black;
  padding-top: 50px;
  padding-bottom: 40px;
  overflow: hidden;
  h1 {
    color: #1da64a;
  }
`;

const Space = styled.div`
  margin-top: 12px;
`;

const BottomBlock = styled.div`
  background-color: #1da64a;
  padding: 48px;

  border-radius: 3px;
  h2 {
    color: white;
    text-align: center;
  }
`;

const Page4 = () => {
  return (
    <Wrapper id="page4">
      <div className="row">
        <div className="twelve columns main-col">
          <h1>Why appoint SRS Property Solutions?</h1>
        </div>
      </div>
      <Space />

      <div className="row">
        <div className="six columns main-col">
          <p>
            Clients are provided with detailed plans of cleaning tasks &
            frequency of tasks on a personalised in-depth cleaning schedule
            which has allowed us to maintain a high standard of cleaning that is
            expected
          </p>
          <p>
            Our hands-on management style means that our senior managers play a
            crucial role in client liaison and customer satisfaction
          </p>
          <p>
            We offer competitive prices,yet do not believe in compromising on
            performance or quality
          </p>
          <p>
            We encourage communication from the client to continuously improve
            our performance
          </p>
          <p>
            Our methods of quality control & supervision has ensured SRS
            Property Solutions is the right choice for you and your business
          </p>
          <p>
            An onsite presence is maintained via regular site visits so we are
            familiar with any staff or OH&S concerns that may arise
          </p>
        </div>
        <div className="six columns main-col">
          <p>Full comprehensive insurance cover</p>
          <p>
            Regular Toolbox talks are conducted so that your safety concerns are
            re-iterated to our staff and everyone is working with safety in
            mind,thus trying to avoid any potential injuries
          </p>
          <p>Continuous improvement to our service and systems</p>
          <p>
            Our 24-hour contact centre enables us to better service our clients!
          </p>
          <p>
            All electrical units are inspected,tagged, the serial numbers and
            repair dates and details are recorded into our log to ensure safety
          </p>
          <p>
            All Supervisors/Cleaning staff are provided with a company uniform
            consisting of a monogrammed polo shirt,with a photo l.D.card.The
            uniform and l.D.card are always worn whilst on your premises
          </p>
        </div>
        <Space />
        <hr />
      </div>
      <Space />
      <div className="row">
        <BottomBlock>
          <h2>
            SRS Property Solutions strive to uphold your company profile and
            reputation,by working with you to build and maintain an open,honest,
            and professional business relationship.
          </h2>
        </BottomBlock>
      </div>
    </Wrapper>
  );
};

export default Page4;
