import React, { Component } from 'react';
import styled from 'styled-components';

const Img = styled.img`
  max-height: 30px;
  margin-right: 24px;
`;
const Li = styled.li`
  padding-top: 12px !important;
`;
const Header = () => {
  return (
    <header id="home">
      <nav id="nav-wrap">
        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
          Show navigation
        </a>
        <a className="mobile-btn" href="#home" title="Hide navigation">
          Hide navigation
        </a>

        <ul id="nav" className="nav">
          <Li className="current">
            {/* <a className="smoothscroll" href="#home"> */}
            <Img src={`images/logo-white.png`} alt="logo" />
            {/* </a> */}
          </Li>
          <li className="current">
            <a className="smoothscroll" href="#home">
              Home
            </a>
          </li>

          {/* <li>
            <a className="smoothscroll" href="#page1">
              Page1
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#page2">
              Page2
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#page3">
              Page3
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#page4">
              Page4
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#page5">
              Page4
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#page6">
              Page6
            </a>
          </li> */}

          <li>
            <a className="smoothscroll" href="#contact">
              Contact
            </a>
          </li>
        </ul>
      </nav>

      <div className="row banner">
        <div className="banner-text">
          <h1 className="responsive-headline">SRS Property Solutions</h1>
          <h3>
            Our aim is to offer our client’s reliability, stability,and quality
            service with a group of highly skilled and supportive management
            systems..
          </h3>
          <hr />
        </div>
      </div>

      <p className="scrolldown">
        <a className="smoothscroll" href="#page1">
          <i className="icon-down-circle"></i>
        </a>
      </p>
    </header>
  );
};

export default Header;
