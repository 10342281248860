import React from 'react';

import styled from 'styled-components';
const Wrapper = styled.section`
  background-color: #1da64a;
  color: white;
  padding-top: 96px;
  /* padding-bottom: 66px; */
  overflow: hidden;
  h1 {
    font-size: 74px;
    line-height: 138%;
  }
  h1,
  h2,
  hr {
    color: white;
  }
`;
const Img = styled.img`
  border-bottom: 8px solid #1da64a;
  margin-bottom: 12px;
`;

const Blue = styled.h2`
  color: #1da64a;
`;
const Space = styled.div`
  margin-top: ${({ space }) => space || '12px'};
`;

const Bottom = styled.div`
  background-color: #1da64a;
  padding: 48px;

  border-radius: 3px;
  h2 {
    color: white;
    text-align: center;
  }
`;

const Page6 = () => {
  return (
    <Wrapper id="page6">
      <div className="row">
        <div className="twelve columns">
          <h1>Our Vision</h1>
          <Space space="24px" />
          <h2>We like to be at one stop shop for our customers</h2>
          <Space space="24px" />
          <p>
            Our vision is to deliver optimum quality service, provide Solutions
            to the customers needs and exceed customer's expectation
          </p>
          <Space space="24px" />
          <hr />
          <Space space="24px" />
          <h1>Our Mission</h1>
          <Space space="24px" />
          <h2>Our mission is to</h2>
          <Space space="24px" />
          <p>
            perform for our customers the highest level of quality services at
            fair and market competitive price
          </p>
          <p>
            To ensure the longevity of our company through repeat and referral
            business achieved by customer satisfaction in all areas including
            timeliness, attention to detail and service-minded attitudes
          </p>
          To maintain the highest levels of professionalism, integrity, honesty,
          and fairness in our relationship with our staff, suppliers,
          professional associates, and customers
          <p></p>
        </div>
      </div>
      <Space space="24px" />
      <div className="row">
        <Img src={`images/cleaning/vent-cleaning.jpg`} alt="Vacuuming" />
      </div>
    </Wrapper>
  );
};

export default Page6;
