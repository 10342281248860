import React from 'react';

import styled from 'styled-components';
const Wrapper = styled.section`
  background-color: #eaeaeafc;
  color: #000;
  padding-top: 50px;
  padding-bottom: 40px;
  overflow: hidden;
`;
const Img = styled.img`
  border-bottom: 8px solid #0f7cc1;
  margin-bottom: 12px;
`;

const Blue = styled.h2`
  color: #0f7cc1;
`;

const Bottom = styled.div`
  background-color: #0f7cc1;
  padding: 48px;

  border-radius: 3px;
  h2 {
    color: white;
    text-align: center;
  }
`;

const Page1 = () => {
  return (
    <Wrapper id="page1">
      <div className="row">
        <Img src={`images/cleaning/first-page.jpg`} alt="Vacuuming" />
      </div>
      <div className="row">
        <div className="six columns">
          <Blue>
            Founded in 2009, SRS Property Solutions is a 100% Australian
            Privately Owned specializing in Cleaning, Property and Ground
            Maintenance services.
          </Blue>
        </div>
        <div className="six columns main-col">
          <div className="row">
            <div className="columns contact-details">
              <p className="address">
                SRS Property Solutions takes pride in developing and maintaining
                lasting business relationships with our clients.Our unique
                approach and commitment to a philosophy of integrity ensures
                that all cleaning and facility services are provided on time, on
                budget and at the highest quality. We offer a comprehensive
                range of cleaning and facility products and services through
                tried and tested,well-disciplined and structured system,
                achieving a high quality,reliable and ethical supply of products
                and services,constantly improving standards and high-end quality
              </p>
              <p className="address111">
                We offer a comprehensive range of cleaning and facility products
                and services through tried and tested,well-disciplined and
                structured system, achieving a high quality,reliable and ethical
                supply of products and services,constantly improving standards
                and high-end quality
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <Bottom>
          <h2>
            Our unique approach and commitment to a philosophy of integrity
            ensures that all cleaning and facility services are provided on
            time, on budget and at the highest quality
          </h2>
        </Bottom>
      </div>
    </Wrapper>
  );
};

export default Page1;
