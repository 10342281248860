import React from 'react';

import styled from 'styled-components';
const Wrapper = styled.section`
  background-color: #eaeaeafc;
  color: black;
  padding-top: 50px;
  padding-bottom: 40px;
  overflow: hidden;
`;
const TopBlock = styled.div`
  background-color: rgb(15, 124, 193, 0.5);
  background-blend-mode: difference;
  padding: 60px;
  h2 {
    color: white;
    text-align: center;
  }
  background-image: url('/images/cleaning/building-cleaning.jpg');
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover;
  @media only screen and (max-width: 460px) {
    padding: 60px 12px;
  }
`;

const Img = styled.img`
  border-bottom: 8px solid #0f7cc1;
  margin-bottom: 12px;
`;

const Space = styled.div`
  margin-top: ${({ space }) => space || '12px'};
`;

const Card = styled.div`
  padding: 24px;
  text-align: center;
  background-color: #fe6928;
  border-radius: 4px;
  margin-right: 12px;
  h4 {
    color: white;
    text-align: center;
  }
  @media only screen and (max-width: 460px) {
    margin-top: 6px;
  }
`;
const Quality = styled.div`
  /* :not(:first-child) {
    margin-left: 12px;
  } */
  :last-child {
    ${Card} {
      /* margin-right: 0px; */
    }
  }
`;

const Page3 = () => {
  return (
    <Wrapper id="page3">
      <div className="row">
        <TopBlock>
          <h2>
            Our aim is to offer our client's reliability, stability,and quality
            service with a group of highly skilled and supportive management
            systems.
          </h2>
        </TopBlock>
      </div>
      <Space space="24px" />

      <div className="row">
        <Quality className="three columns">
          <Card>
            <h4>Reliability</h4>
          </Card>
        </Quality>
        <Quality className="three columns">
          <Card>
            <h4>Quality</h4>
          </Card>
        </Quality>
        <Quality className="three columns">
          <Card>
            <h4>Trust</h4>
          </Card>
        </Quality>
        <Quality className="three columns">
          <Card>
            <h4>Care</h4>
          </Card>
        </Quality>
      </div>
      <Space />

      <div className="row">
        <Quality className="three columns">
          <Card>
            <h4>Stability</h4>
          </Card>
        </Quality>
        <Quality className="three columns">
          <Card>
            <h4>Highly Skilled</h4>
          </Card>
        </Quality>
        <Quality className="three columns">
          <Card>
            <h4>Progressive</h4>
          </Card>
        </Quality>
        <Quality className="three columns">
          <Card>
            <h4>Passionate</h4>
          </Card>
        </Quality>
      </div>
      <Space space="24px" />

      <div className="row">
        <Img src={`images/cleaning/grass-mowing.jpg`} alt="Trolley" />
      </div>
    </Wrapper>
  );
};

export default Page3;
