import React from 'react';

import styled from 'styled-components';
const Wrapper = styled.section`
  background-color: #eaeaeafc;
  color: black;
  padding-top: 50px;
  padding-bottom: 40px;
  overflow: hidden;

  /* background: #1f1f1f url(/images/testimonials-bg.jpg) no-repeat center center; */
  background-size: cover !important;
  -webkit-background-size: cover !important;
  background-attachment: fixed;
  position: relative;
  min-height: 200px;
  width: 100%;
  overflow: hidden;
`;
const Img = styled.img`
  border-bottom: 8px solid #1da64a;
  margin-bottom: 12px;
`;

const Blue = styled.h2`
  color: #1da64a;
`;
const Space = styled.div`
  margin-top: 12px;
`;

const Bottom = styled.div`
  background-color: #1da64a;
  padding: 48px;

  border-radius: 3px;
  h2 {
    color: white;
    text-align: center;
  }
`;

const Page2 = () => {
  return (
    <Wrapper id="page2">
      <div className="row">
        <Img src={`images/cleaning/trolley.jpg`} alt="Trolley" />
      </div>
      <Space />
      <div className="row">
        <div className="twelve columns">
          <Blue>
            Having over 40 Years of experience within our Management Team with
            extensive knowledge Retail, Industrial, Event Venues, Public sectors
            & Hotels, Strata, Clubs and Nursing Homes in Cleaning, Grounds and
            Property Maintenance.
          </Blue>
        </div>
      </div>
      <Space />

      <div className="row">
        <div className="six columns main-col">
          <p>
            At SRS we understand service,it is the founding stone of our
            company.We are dynamic, committed and focused on doing everything we
            can to rapidly to our client's needs.
          </p>
        </div>
        <div className="six columns main-col">
          <p>
            SRS Property Solutions strive to uphold your company profile and
            reputation,by working with you to build and maintain an open,honest,
            and professional business relationship.
          </p>
        </div>
        <Space />
        <hr />
      </div>
      <Space />

      <div className="row">
        <Bottom>
          <h2>
            SRS Property Solutions - Providing building owners and managers
            individual and co-ordinated contract package services encompassing
          </h2>
        </Bottom>
      </div>
    </Wrapper>
  );
};

export default Page2;
