import React, { Component } from 'react';

const Footer = () => (
  <footer>
    <div className="row">
      <div className="twelve columns">
        <ul className="copyright">
          <li>`&copy; Copyright {new Date().getFullYear()} SRS Property`</li>
        </ul>
      </div>
      <div id="go-top">
        <a className="smoothscroll" title="Back to Top" href="#home">
          <i className="icon-up-open"></i>
        </a>
      </div>
    </div>
  </footer>
);

export default Footer;
