import React, { useState } from 'react';
import styled from 'styled-components';

const Widget = styled.div`
  padding: 30px;
  color: #ebeeee;
  background: #373233;
  box-shadow: -3px 0px 15px #2d2c2c;
`;
const Bold = styled.b`
  font-weight: bold;
`;

const Contact = () => {
  const [state, setNewState] = useState({});

  const setState = (newState) =>
    setNewState((prevState) => ({ ...prevState, ...newState }));
  const handleChange = (e) => {
    e.stopPropagation();
    console.log(e.target.name);
    setState({ [e.target.name]: e.target.value });
  };

  return (
    <section id="contact">
      <div className="row section-head">
        <div className="two columns header-col">
          <h1>
            <span>Get In Touch.</span>
          </h1>
        </div>

        <div className="ten columns">
          <p className="lead">{'message'}</p>
        </div>
      </div>

      <div className="row">
        <div className="eight columns">
          <form action="" method="post" id="contactForm" name="contactForm">
            <fieldset>
              <div>
                <label htmlFor="contactName">
                  Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  defaultValue=""
                  size="35"
                  id="contactName"
                  name="contactName"
                  onChange={handleChange}
                  value={state.contactName}
                />
              </div>

              <div>
                <label htmlFor="contactEmail">
                  Email <span className="required">*</span>
                </label>
                <input
                  type="text"
                  defaultValue=""
                  size="35"
                  id="contactEmail"
                  name="contactEmail"
                  onChange={handleChange}
                  value={state.contactEmail}
                />
              </div>

              <div>
                <label htmlFor="contactSubject">Subject</label>
                <input
                  type="text"
                  defaultValue=""
                  size="35"
                  id="contactSubject"
                  name="contactSubject"
                  onChange={handleChange}
                  value={state.contactSubject}
                />
              </div>

              <div>
                <label htmlFor="contactMessage">
                  Message <span className="required">*</span>
                </label>
                <textarea
                  cols="50"
                  rows="15"
                  id="contactMessage"
                  name="contactMessage"
                  onChange={handleChange}
                  value={state.contactMessage}
                ></textarea>
              </div>

              <div>
                <button className="submit">Submit</button>
                <span id="image-loader">
                  <img alt="" src="images/loader.gif" />
                </span>
              </div>
            </fieldset>
          </form>

          <div id="message-warning"> Error boy</div>
          <div id="message-success">
            <i className="fa fa-check"></i>Your message was sent, thank you!
            <br />
          </div>
        </div>

        <aside className="four columns footer-widgets">
          <Widget className="widget widget_contact">
            <h4>SRS Property Solutions</h4>
            <hr />
            <Bold>(02) 8860 6488</Bold> <br />
            <Bold>E</Bold> info@srsproperty.com.au
            <p className="address">
              Level 5 Nexus Building,
              <br />4 Columbia Court,
              <br />
              Norwest NSW 2153
              <br />
              <br />
              <span>
                <Bold>ABN:</Bold> 38 135 116835
              </span>
            </p>
          </Widget>
        </aside>
      </div>
    </section>
  );
};

export default Contact;
