import React from 'react';

import styled from 'styled-components';
const Wrapper = styled.section`
  background-color: #eaeaeafc;
  color: black;
  padding-top: 50px;
  padding-bottom: 40px;
  overflow: hidden;
  h4 {
    color: #0f7cc1;
  }
`;
const Img = styled.img`
  border-bottom: 8px solid #1da64a;
  margin-bottom: 12px;
`;

const Blue = styled.h2`
  color: #1da64a;
`;
const Space = styled.div`
  margin-top: ${({ space }) => space || '12px'};
`;

const Bottom = styled.div`
  background-color: #1da64a;
  padding: 48px;

  border-radius: 3px;
  h2 {
    color: white;
    text-align: center;
  }
`;

const Page5 = () => {
  return (
    <Wrapper id="page5">
      <div className="row">
        <div className="twelve columns">
          <Blue>
            SRS understand the importance of creating an effective team that is
            flexible in their approach to the provision of a high standard of
            service to our clients. SRS Property Solutions offers a focus on its
            team approach and tailors this to suit your needs as outlined below
          </Blue>
        </div>
      </div>
      <Space space="24px" />

      <div className="row">
        <div className="six columns main-col">
          <h4>Induction</h4>

          <p>
            Employment at SRS PROPERTY SOLUTIONS PTY LTD begins with an initial
            induction session that is then followed by on site induction
            covering site-specific emergency procedures,areas of cleaning and
            site policies.
          </p>

          <h4>Flexibility</h4>

          <p>
            A diverse cleaning and grounds maintenance routine that allows work
            to be performed am always and I or pm to ensure the highest
            presentation.
          </p>

          <h4>Training</h4>

          <p>
            Cleaning personnel engaged at client site will be requested to
            complete all training programs as provided and engage in Level 2 & 3
            Certificates.
          </p>
          <Img src={`images/logo.png`} alt="logo" />
        </div>
        <div className="six columns main-col">
          <h4>Supervision</h4>

          <p>
            Senior Managers will be responsible to the contract and will be
            supported by the site leading hand. Our Managers are contactable 24
            hours per day.
          </p>

          <h4>Relief Staff</h4>

          <p>
            Only experienced cleaners will cover for absentee staff. They are
            drawn from our casual list,which the site leading hand will be
            provided with.
          </p>

          <h4>Security</h4>

          <p>
            Where required,employees will have either a State or Federal Police
            clearance. Employees will be SRS Property Solutions Pty Ltd supplied
            with an identification badge and company uniform.
          </p>

          <h4>Quality Assurance</h4>

          <p>
            Quality is,and always will be,the number one objective". To ensure
            that our company complies with ISO 9001:2000,daily and twice weekly
            inspections are carried out by a senior manager to ensure all
            processes and procedures are current and reflect change identified
            as part of our continuing quality improvement process.
          </p>
        </div>
        <Space />
        <hr />
      </div>
      <Space space="24px" />
      <div className="row">
        <Img src={`images/cleaning/window-cleaning.jpg`} alt="Vacuuming" />
      </div>
      <Space space="24px" />

      <div className="row">
        <Bottom>
          <h2>
            SRS Property Solutions - Providing building owners and managers
            individual and co-ordinated contract package services encompassing
          </h2>
        </Bottom>
      </div>
    </Wrapper>
  );
};

export default Page5;
