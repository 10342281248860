import React from 'react';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import FirstPage from './Components/Page1';
import SecondPage from './Components/Page2';
import ThirdPage from './Components/Page3';
import FourthPage from './Components/Page4';
import FifthPage from './Components/Page5';
import SixthPage from './Components/Page6';
import Contact from './Components/Contact';

const App = () => (
  <div className="App">
    <Header />
    <FirstPage />
    <SecondPage />
    <ThirdPage />
    <FourthPage />
    <FifthPage />
    <SixthPage />

    <Contact />
    <Footer />
  </div>
);

export default App;
